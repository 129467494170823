@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');


/* @tailwind base;
@tailwind components;
@tailwind utilities; */


.collapse {
    visibility: visible !important;
}

:root {
    --primary-color: #0062FF;
    --yellow: #FFC542 !important;
    --blue: #50B5FF !important;
    --green: #82C43C !important;
    --orange: #FF974A !important;
    --red: #FC5A5A !important;
    --voilet: #A461D8 !important;
    --pink: #FF9AD5 !important;
    --black: #171725 !important;
    --white: #FFFFFF !important;
    --indigo: #3B37F5 !important;
    --black-dark-800: #44444F;
    --black-dark-700: #696974;
    --black-dark-600: #92929D;
    --black-dark-500: #B5B5BE;
    --black-dark-400: #D5D5DC;
    --black-dark-300: #E2E2EA;
    --black-dark-200: #F1F1F5;
    --black-dark-100: #FAFAFB;
    --neutral-700: #374151;
    --Blue-100: #F0F6FF;
    --text-black: #252C32;
    --value: 2px;
    --pr-light-blue: rgb(0 98 255 / 10%);
    --header-height: 48px;
    --toastify-toast-width: 400px !important;

}


:root {
    --box-shadow: 2px 2px 8px rgb(213 213 220 / 80%);
}

:root {
    --popins: 'Poppins', sans-serif;
    --worksans: 'Work Sans', sans-serif;
    --roboto: 'Roboto', sans-serif;
    --inter: 'Inter', sans-serif;
}

:root {
    --po-regular: 400;
    --po-medium: 500;
    --po-semi: 600;
    --po-bold: 700;
}

:root {
    --wo-light: 300;
    --wo-regular: 400;
    --wo-medium: 500;
    --wo-bold: 700;
}

.worksans {
    font-family: var(--work);
}

.h-font {
    font-family: var(--popins);

}

.indigo {
    color: var(--indigo);
}

.black-dark-800 {
    color: var(--black-dark-800)
}

.text-black {
    color: var(--text-black);
}

.neutral-700 {
    color: var(--neutral-700)
}

.black-dark-700 {
    color: var(--black-dark-700)
}

.black-dark-600 {
    color: var(--black-dark-600);
}

.black {
    color: var(--black);
}

.color-red {
    color: var(--red);
}

.po {
    font-family: var(--popins);

}


.inter {
    font-family: var(--inter) !important;
}

.ro {
    font-family: var(--roboto) !important;

}

.fw-regular {
    font-weight: var(--po-regular);
}

.fw-po-medium {
    font-weight: var(--po-medium);
}

.fw-po-semi {
    font-weight: var(--po-semi);
}

.fw-po-bold {
    font-weight: 700;
}

.ls5 {
    letter-spacing: 0.5px;
}

.ls4 {
    letter-spacing: 0.4px;
}

.ls5 {
    letter-spacing: 0.5px;
}

.ls2 {
    letter-spacing: 0.2px;
}

.del-hover:hover svg path {
    fill: var(--red);
}

.del-hover:hover {
    color: var(--red);
}



.pr-hover:hover svg path {
    fill: var(--primary-color);
}

.del-btn-pr-hover:hover svg path {
    fill: #ffffff !important;
}

.pr-hover:hover {
    color: var(--primary-color) !important;
}


.board {
    background-color: var(--white);
    border-radius: 8px;
}

.border-color {
    border-color: var(--black-dark-300)
}

.border-primary {
    border-color: var(--primary-color);
}

img {
    max-width: 100%;
    height: auto;
}


body {
    font-family: var(--popins) !important;
    font-size: calc(var(--value) * 7) !important;
    line-height: 1.5 !important;
    color: var(--black) !important;
    background-color: #FAFAFB !important;
    font-weight: 400 !important;
    letter-spacing: 0.1px;
    -webkit-font-smoothing: antialiased !important;
    height: 100%;
    overflow: hidden;

}


.lh_1_5 {
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.5 !important;
}


/* table styling */
.table tbody tr:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 4px;
    width: 0;
    box-shadow: 0px 4px 6px rgb(68 68 79 / 10%);
    -webkit-box-shadow: 0px 4px 6px rgb(68 68 79 / 10%);
    -moz-box-shadow: 0px 4px 6px rgb(68 68 79 / 10%);
    z-index: 1;
    opacity: 0;
    transition: 0.5s all;
}


.table tbody tr:hover:after {
    width: 100%;
    opacity: 9;
}

td.dropdown.view-more.show {
    z-index: 9;
}

table thead {
    background-color: #FAFAFB;
    position: sticky;
    top: 0;
    z-index: 9;
}

thead tr th {
    padding-left: 16px !important;
    padding-right: 16px !important;
    z-index: 1;
    color: var(--black-dark-800);
    background-color: white;
    white-space: nowrap;
    letter-spacing: .5px;
    border: 0 !important;
    font-family: var(--popins) !important;
    font-weight: 500;

}

.table td,
.table th {
    border-bottom: 0.5px solid rgb(213 213 220 / 60%) !important;
}

.table tbody tr td {
    font-family: var(--worksans) !important;
    color: var(--black);
    font-size: 14px !important;
    line-height: 22px;
    letter-spacing: 0.1px;
    vertical-align: middle;
    padding: 8px 16px !important;
    white-space: nowrap;
}

.def-table.plantable .table tbody tr td:nth-child(1) {
    padding-left: 20px !important;
}

table tr {
    position: relative;
}

/* 
.table tbody tr:hover {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    box-shadow: 0px 4px 6px rgb(68 68 79 / 10%);
    -webkit-box-shadow: 0px 4px 6px rgb(68 68 79 / 10%);
    -moz-box-shadow: 0px 4px 6px rgb(68 68 79 / 10%);

} */

.table tr:not(.booking-complete, .old-leads) td {
    background-color: var(--white);
}

.table .booking-complete td,
.table .old-leads td {
    background: #F7F8FA;
}

/*  */
.beta {
    color: #0062FF;
    letter-spacing: 1px;
    font-size: 8px;
    font-weight: 700;
    background: #E5EFFF;
    border: 1.2px solid #0062FF;
    border-radius: 2px;
    padding: 3px 4px;
    margin-left: 6px;
    display: none;
}

.form-control:disabled,
.form-control[readonly] {
    /* background-color: var(--white); */
    background-color: #f2f2f2 !important;
    color: #999999 !important;
}

table {
    font-family: var(--worksans);
    font-weight: 400;
}

.dropdown {
    font-family: var(--popins) !important;
}


.cursor-pointer:hover {
    cursor: pointer;
}

.outline-btn {
    color: var(--primary-color);
    background-color: var(--white);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    padding: calc(var(--value) * 3) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    border: 1px solid var(--primary-color);
    font-weight: var(--wo-medium);
}

button.outline-btn:focus {
    border-color: var(--primary-color) !important;
}

.form-control:focus {
    box-shadow: unset;
}

::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: var(--black-dark-500) !important;
}

.css-14el2xx-placeholder {
    font-size: 14px;
    font-weight: 400;
    color:
        var(--black-dark-500) !important;
}

.pr-btn {
    color: var(--white);
    background-color: var(--primary-color);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    padding: calc(var(--value) * 2) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    border: 1px solid var(--primary-color);
    font-weight: var(--wo-medium);
    white-space: nowrap;
}

.pr-btn:hover {
    color: var(--white);
    opacity: 0.9;
}

.remove-hover:hover {
    opacity: 9 !important;
}

.cold-btn {
    color: var(--white);
    background-color: var(--blue);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    padding: calc(var(--value) * 2) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    border: 0;
    font-weight: var(--wo-medium);
}

.dead-btn {
    color: var(--white);
    background-color: var(--red);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    padding: calc(var(--value) * 2) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    border: 0;
    font-weight: var(--wo-medium);
}

.hot-btn {
    color: var(--white);
    background-color: var(--green);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    padding: calc(var(--value) * 2) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    border: 0;
    font-weight: var(--wo-medium);
}

.warm-btn {
    color: var(--white);
    background-color: var(--orange);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    padding: calc(var(--value) * 2) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    border: 0;
    font-weight: var(--wo-medium);
}

.pr-text-btn {
    color: var(--primary-color);
    background-color: var(--white);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    padding: calc(var(--value) * 2) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    border: 0;
}

.filter-btn .dropdown-menu {
    width: 240px;
    box-shadow: var(--box-shadow);
    border-radius: calc(var(--value) * 4);
    padding: calc(var(--value) * 6);
    border: 0;
}

.filter-btn ul.select-name li a {
    background: var(--white);
    line-height: 22px;
    letter-spacing: 0.1px;
    color: var(--black-dark-800);
    border-radius: 4px;
}

.filter-btn ul.select-name li a:hover {
    background: var(--black-dark-200);
}

.filter-btn ul.select-name li+li {
    margin-top: 8px;
}

.filter-btn ul.select-name {
    max-height: 156px;
    overflow: auto;
    padding-right: 15px;
}

.filter-btn ul.select-name li a.active {
    background: var(--Blue-100);
    color: var(--primary-color);
}

.pr-btn span {
    margin-left: calc(var(--value) * 10);
}

.pr-color {
    color: var(--primary-color)
}

.red-color {
    color: var(--red) !important;
}

.orange-color {
    color: var(--orange)
}

.green-color {
    color: var(--green);
}

.orange-hover {
    color: var(--orange)
}

.red-hover:hover {
    color: var(--red);
}

.gap-8 {
    gap: 8px
}

.gap-4 {
    gap: 4px
}

.gap-12 {
    gap: 12px;
}

.gap-14 {
    gap: 14px;
}

.gap-24 {
    gap: 24px;
}

.gap-x-32 {
    column-gap: 32px;
}

.gap-x-8 {
    column-gap: 8px;
}

.gap-x-20 {
    column-gap: 32px;
}

.gap-80 {
    gap: 80px
}

.gap-y-20 {
    row-gap: 20px
}

.gap-y-14 {
    row-gap: 14px
}

.opacity-0 {
    opacity: 0 !important;
}

.fz10 {
    font-size: calc(var(--value) * 5);
}

.fz12 {
    font-size: calc(var(--value) * 6);
}

.fz14 {
    font-size: calc(var(--value) * 7);
}

.fz16 {
    font-size: calc(var(--value) * 8);
}

.fz18 {
    font-size: calc(var(--value) * 9);
}

.fz20 {
    font-size: calc(var(--value) * 10);
}

.fz22 {
    font-size: calc(var(--value) * 11);
}

.fz24 {
    font-size: calc(var(--value) * 12);
}

.fz28 {
    font-size: calc(var(--value) * 14);
}

.fz32 {
    font-size: calc(var(--value) * 16);
}

.fz36 {
    font-size: calc(var(--value) * 18);
}

.fz48 {
    font-size: calc(var(--value) * 24);
}

.fz56 {
    font-size: calc(var(--value) * 28);
}

.mt4 {
    margin-top: calc(var(--value) * 2);
}

.mt-8 {
    margin-top: calc(var(--value) * 4);
}

.mt-10 {
    margin-top: calc(var(--value) * 5);
}

.mt-12 {
    margin-top: calc(var(--value) * 6);
}


.mt-16 {
    margin-top: calc(var(--value) * 8);
}

.mt-18 {
    margin-top: calc(var(--value) * 9);
}

.mt-20 {
    margin-top: calc(var(--value) * 10);
}

.mt-22 {
    margin-top: calc(var(--value) * 11);
}

.mt-24 {
    margin-top: calc(var(--value) * 12);
}

.mt-30 {
    margin-top: calc(var(--value) * 15);
}

.mt-32 {
    margin-top: calc(var(--value) * 16);
}


.mt-34 {
    margin-top: calc(var(--value) * 17);
}

.mt-36 {
    margin-top: calc(var(--value) * 18);
}

.mt-56 {
    margin-top: calc(var(--value) * 28);
}

.mt-64 {
    margin-top: calc(var(--value) * 32);
}

.mt-40 {
    margin-top: calc(var(--value) * 20);
}

.mt-42 {
    margin-top: calc(var(--value) * 21);
}

.mt-50 {
    margin-top: calc(var(--value) * 25);
}

.ml-8 {
    margin-left: calc(var(--value) * 4);
}

.ml-12 {
    margin-left: calc(var(--value) * 6);
}

.ml-16 {
    margin-left: calc(var(--value) * 8);
}

.ml-20 {
    margin-left: calc(var(--value) * 10);
}

.ml-24 {
    margin-left: calc(var(--value) * 12);
}

.ml-26 {
    margin-left: calc(var(--value) * 13);
}

.ml-28 {
    margin-left: calc(var(--value) * 14);
}

.px-64 {
    padding-left: calc(var(--value) * 32);
    padding-right: calc(var(--value) * 32);
}

.mb4 {
    margin-bottom: calc(var(--value) * 2);
}

.mb-6 {
    margin-bottom: calc(var(--value) * 3);
}

.mb-8 {
    margin-bottom: calc(var(--value) * 4);
}

.mb-12 {
    margin-bottom: calc(var(--value) * 6);
}

.mb-14 {
    margin-bottom: calc(var(--value) * 7);
}

.mb-16 {
    margin-bottom: calc(var(--value) * 8);
}

.mb-18 {
    margin-bottom: calc(var(--value) * 9);
}

.mb-20 {
    margin-bottom: calc(var(--value) * 10);
}

.mb-24 {
    margin-bottom: calc(var(--value) * 12);
}

.mb-22 {
    margin-bottom: calc(var(--value) * 11);
}

.mb-28 {
    margin-bottom: calc(var(--value) * 14);
}

.mb-32 {
    margin-bottom: calc(var(--value) * 16);
}

.mb-36 {
    margin-bottom: calc(var(--value) * 18);
}

.mb-40 {
    margin-bottom: calc(var(--value) * 20);
}

.mb-44 {
    margin-bottom: calc(var(--value) * 22);
}


.mr4 {
    margin-right: calc(var(--value) * 2);
}

.mr-8 {
    margin-right: calc(var(--value) * 4);
}

.mr-10 {
    margin-right: calc(var(--value) * 5);
}

.mr-12 {
    margin-right: calc(var(--value) * 6);
}

.mr-16 {
    margin-right: calc(var(--value) * 8);
}

.mr-18 {
    margin-right: calc(var(--value) * 9);
}

.mr-20 {
    margin-right: calc(var(--value) * 10);
}

.mr-24 {
    margin-right: calc(var(--value) * 12);
}

.mr-26 {
    margin-right: calc(var(--value) * 13);
}

.mx-30 {
    margin-right: calc(var(--value) * 15);
    margin-left: calc(var(--value) * 15);
}

.pl4 {
    padding-left: calc(var(--value) * 2);
}

.p-20 {
    padding: calc(var(--value) * 10);
}

.h-line {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


.lh-16 {
    line-height: 16px !important;
}

.lh-20 {
    line-height: 20px !important;
}

.lh-22 {
    line-height: 22px !important;
}

.lh-24 {
    line-height: 24px !important;
}

.lh-40 {
    line-height: 40px !important;
}

.pt-30 {
    padding-top: calc(var(--value) * 15);
}

.pt-8 {

    padding-top: calc(var(--value) * 4);
}

.pt-10 {

    padding-top: calc(var(--value) * 5);
}

.pt-12 {

    padding-top: calc(var(--value) * 6);
}

.pb-10 {

    padding-bottom: calc(var(--value) * 5);
}

.pb-12 {

    padding-bottom: calc(var(--value) * 6);
}

.pt-20 {

    padding-top: calc(var(--value) * 10);
}

.pb-20 {

    padding-bottom: calc(var(--value) * 10);
}

.px-6 {
    padding-left: calc(var(--value) * 3);
    padding-right: calc(var(--value) * 3);
}


.px-12 {
    padding-left: calc(var(--value) * 6);
    padding-right: calc(var(--value) * 6);
}

.px-16 {
    padding-left: calc(var(--value) * 8);
    padding-right: calc(var(--value) * 8);
}

.px-20 {
    padding-left: calc(var(--value) * 10);
    padding-right: calc(var(--value) * 10);
}

.py-20 {
    padding-top: calc(var(--value) * 10);
    padding-bottom: calc(var(--value) * 10);
}

.pl-20 {
    padding-left: calc(var(--value) * 10);

}

.pr-12 {
    padding-right: calc(var(--value) * 6);

}

.pr-20 {
    padding-right: calc(var(--value) * 10);

}

.pr-32 {
    padding-right: calc(var(--value) * 16);

}

.px-22 {
    padding-left: calc(var(--value) * 11);
    padding-right: calc(var(--value) * 11);
}


.pl-24 {
    padding-left: calc(var(--value) * 12);
}


.pl-64 {
    padding-left: calc(var(--value) * 32);
}

.px-24 {
    padding-left: calc(var(--value) * 12);
    padding-right: calc(var(--value) * 12);
}

.py4 {
    padding-top: calc(var(--value) * 4);
    padding-bottom: calc(var(--value) * 4);
}

.px8 {
    padding-left: calc(var(--value) * 4);
    padding-right: calc(var(--value) * 4);
}

.px-32 {
    padding-left: calc(var(--value) * 16);
    padding-right: calc(var(--value) * 16);
}

.px-40 {
    padding-left: calc(var(--value) * 20);
    padding-right: calc(var(--value) * 20);
}


.py-6 {
    padding-top: calc(var(--value) * 3);
    padding-bottom: calc(var(--value) * 3);
}

.py-8 {
    padding-top: calc(var(--value) * 4);
    padding-bottom: calc(var(--value) * 4);
}

.py-10 {
    padding-top: calc(var(--value) * 5);
    padding-bottom: calc(var(--value) * 5);
}

.py-12 {
    padding-top: calc(var(--value) * 6);
    padding-bottom: calc(var(--value) * 6);
}

.py-16 {
    padding-top: calc(var(--value) * 8);
    padding-bottom: calc(var(--value) * 8);
}

.py-24 {
    padding-top: calc(var(--value) * 12);
    padding-bottom: calc(var(--value) * 12);
}

sup {
    color: var(--red);
    top: -5px;
}

buton:focus {
    outline: 0;
    box-shadow: unset;
    border: 0;
}

button:focus {
    outline: 0 !important;
    box-shadow: unset !important;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a,
a:hover {
    text-decoration: none !important;
    cursor: pointer;
    white-space: nowrap;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.input-active input.searchinput {
    border: 1px solid var(--primary-color);
}

.view-less svg {
    transform: rotate(180deg);
}

.search-input .close-icon,
.search-input .serch-icon {
    position: absolute;
    cursor: pointer;
}

.search-input .close-icon {
    right: 12px;
    opacity: 9;
    top: 12px
}

.settings-title .search-input .close-icon {
    top: 12px;
}

.search-input .serch-icon {
    left: 4px;
    top: 4px;
    cursor: auto;
}

.text-underline {
    text-decoration: underline !important;
}

.search-input input {
    width: 216px;
    background: var(--white);
    border: 1px solid var(--black-dark-400);
    padding: 0px 30px 0px 40px;
    height: calc(var(--value) * 17);
    border-radius: calc(var(--value) * 2);
    line-height: calc(var(--value) * 11);
    color: var(--black-dark-700);
    font-weight: 400;
    font-size: calc(var(--value) * 7);
    transition: 0.5s all;
    font-family: var(--roboto);
}

.search-input input::placeholder {
    color: var(--black-dark-700);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.4px;
    font-family: var(--roboto);
}

.search-input input:focus,
.form-control:focus {
    border: 1px solid var(--primary-color) !important;
    outline: 0 !important;
    box-shadow: unset !important;
    box-sizing: border-box;
}

.radius {
    border-radius: 4px;
}

.radius-8 {
    border-radius: 8px;
}

.radius-6 {
    border-radius: 6px;
}

.intent-drop .dropdown-menu {
    width: 127px;
    min-width: unset;
    box-shadow: var(--box-shadow);
    border-radius: calc(var(--value) * 4);
    padding: calc(var(--value) * 6) calc(var(--value) * 4);
    border: 0;
}




/* Header css  */
.header-top {
    background: var(--primary-color);
    color: white;
    padding: 4px;
}

.header-top .subscribe-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.header-top .subscribe-text a {
    border: 1px solid white;
    padding: 4px 18px;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
    background: white;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 4px;
}


.header-top+header+.main-div {
    /* height: calc(var(--app-height) - var(--header-height) - 34px); */
    height: 100vh;
}

.header-top+header+.main-div.user-leads-details {
    /* height: calc(var(--app-height) - var(--header-height) - 45px); */
    height: 100vh;
}

.header-top+header+.main-div .lead-table {
    min-height: calc(100vh - 275px);
    max-height: calc(100vh - 275px);
}

.subscribe-modal .modal-dialog.modal-dialog-centered .modal-content .modal-body {
    padding: 0;
}

.fade.subscribe-modal.modal-background.modal.show {
    background: rgba(0, 0, 0, 0.45);
    backdrop-filter: blur(2.4px);
}

.subscribe-modal .modal-dialog.modal-dialog-centered .modal-content {
    background: #FFFFFF;
    box-shadow: inset -1px 0px 0px #E2E2EA;
    border-radius: 16px;
    overflow: hidden;
    max-width: 530px;
    border: 0;
    padding-bottom: 48px;
    padding-top: 38px;
}

.subscribe-modal .modal-dialog.modal-dialog-centered .modal-content a.close {
    right: 20px;
    top: -20px;
    position: absolute;
}


.subscribe-modal .modal-dialog.modal-dialog-centered .modal-content {
    border: 0;
}

.subscribe-modal .modal-dialog.modal-dialog-centered .modal-content img {
    height: 170px;
}

.header-top .subscribe-text {
    line-height: 26px;
}

.subscribe-text .timer-box {
    flex-direction: row;
    width: auto;
    height: auto;
    background: transparent;
}

.subscribe-text .timer-box span {
    color: white;
    font-size: 14px !important;
    line-height: 22px !important;
}

.subscribe-text .timer-box span:nth-child(1) {
    width: 23px;
    height: 20px;
    background: white;
    margin: 0 5px;
    border-radius: 4px;
    color: var(--primary-color);
}

header {
    padding: calc(var(--value) * 2) calc(var(--value) * 9);
    background-color: var(--white);
    box-shadow: inset 0px -1px 0px var(--black-dark-300);
}

.contact-us .dropdown>button:hover svg path {
    fill: var(--primary-color);
}

.contact-us .dropdown-menu button {
    color: var(--black-dark-800);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 22px;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: var(--popins);
}

.contact-us .dropdown-menu {
    padding: 8px !important;
}

.contact-us .dropdown-menu button svg {
    margin-right: 18px;
}

.contact-us .dropdown-menu button:hover svg path {
    fill: var(--primary-color);
}

.contact-us .dropdown-menu button:hover {
    color: var(--primary-color);
}

.user-block {
    display: flex;
    align-items: center;
}

.user-block h1 {
    margin: 0;
    padding: 0;
}

.user-block p {
    padding: 0;
    margin: 0;
}

.user-block .profile-circle {
    width: 32px;
    height: 32px;
    background: var(--blue);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.1px;
    margin-right: 8px;
}

.user-block .profile-name h1 {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: var(--black);
    text-align: left;
    margin-bottom: 2px;
    line-height: 14px;
}

.user-block .profile-name p {
    margin-bottom: 0;
    letter-spacing: 0.8px;
    font-size: 12px;
    line-height: 14px;
    color: var(--black-dark-600);
}

.column-filter .dropdown-menu a:hover {
    background: var(--black-dark-200);
}

td.asign-td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    margin-right: 30px;
}

/* 
.sidebar:hover {
    width: 228px;
} */
.sidebar ul li.active.inventory svg path {
    fill: none;
    stroke: var(--primary-color);
}

.sidebar ul li.inventory:hover svg path {
    stroke: var(--primary-color);
    fill: none;
}

.sidebar ul li.active.inventory:hover svg path {
    stroke: var;
}


.sidebar ul li.active.inventory svg path {
    fill: none;
    stroke: var(--primary-color);
}

.sidebar ul li.inventory:hover svg path {
    stroke: var(--primary-color);
    fill: none;
}

.sidebar ul li.active.inventory:hover svg path {
    stroke: var;
}


.sidebar ul li i {
    font-weight: 600;
    font-size: 8px;
    line-height: 9px;
    background: var(--primary-color);
    opacity: 9;
    position: absolute;
    right: -8px;
    color: white;
    font-style: normal;
    padding: 2px 4px;
    letter-spacing: 0.02em;
    border-radius: 3px;
    top: -4px;
}

.sidebar ul li.learning span {
    width: 155px;
}

.sidebar ul li:nth-child(1) a span {
    width: 150px;
}

.sidebar ul li a:hover span {
    opacity: 9;
    display: inline-block;
}

.line {
    border-bottom: 1px solid var(--black-dark-400)
}

.bg-tr {
    background-color: transparent !important;
}

.bg-red {
    background-color: var(--red);
}

.select-clear+.select-clear {
    margin-left: 8px;
}

.select-clear {
    color: var(--black);
    font-size: 14px;
    line-height: 22px;
}

.short-btn .dropdown-menu {
    width: 240px;
    box-shadow: var(--box-shadow);
    border-radius: calc(var(--value) * 4);
    padding: calc(var(--value) * 6);
    border: 0;
}

.a-z-short button:hover {
    background-color: var(--black-dark-200);
}

.close-btn:hover svg path {
    fill: var(--primary-color);
}

.a-z-short button {
    background: no-repeat;
    border: 0;
    font-size: calc(var(--value) * 7);
    color: var(--black-dark-700);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 12px;
    border-radius: 4px;
}

.a-z-short {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.a-z-short button span {
    margin: 0 6px;
}

.a-z-short button.active {
    background: var(--primary-color);
    color: var(--white);
}

.a-z-short button.active svg path {
    fill: var(--white);
}

.a-z-short button+button {
    margin-left: 6px;
}

.filter-btn>button {
    border: 4px;
    color: var(--black-dark-700);
    padding: 4px 12px;
    background-color: initial;
    white-space: nowrap;
    border-radius: 4px;
}

.short-btn>button.active,
.filter-btn>button.active {
    background: var(--Blue-100);
    color: var(--primary-color);

}

.serch-and-filter-blcok {
    margin-right: 20px;
}

.filter-btn>button.active svg path {
    fill: var(--primary-color);
}

.short-btn>button.active svg path {
    stroke: var(--primary-color);
}

.filter-btn>button:hover svg path {
    fill: var(--primary-color);
}

.short-btn>button:hover svg path {
    stroke: var(--primary-color);
}

.short-btn>button:hover,
.filter-btn>button:hover {
    background: #F1F1F5;
    color: var(--black) !important;
}

.short-btn>button {
    border: 4px;
    color: #696974;
    color: var(--black-dark-700);
    padding: 4px 12px;
    background-color: initial;

}

.short-btn>button span,
.filter-btn>button span {
    font-size: calc(var(--value) * 8);
    line-height: calc(var(--value) * 12);
    letter-spacing: 0.5px;
}

.intent-drop .dropdown-menu a+a {
    margin-top: 12px;
}

.outline,
.outline-leads {
    background: transparent;
    letter-spacing: 0.1px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    padding: calc(var(--value) * 2) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    font-weight: 400;
    min-width: 190px;
    display: flex;
    justify-content: space-between;
}

.pr-btn:hover {
    background-color: var(--primary-color);
    color: var(--white);
    opacity: 0.9;
}

.hot-btn:hover {
    background-color: var(--green);
    color: var(--white);
    opacity: 0.9;
}

.warm-btn:hover {
    background-color: var(--orange);
    color: var(--white);
    opacity: 0.9;
}

.cold-btn:hover {
    background-color: var(--blue);
    color: var(--white);
    opacity: 0.9;
}

.dead-btn:hover {
    background-color: var(--red);
    color: var(--white);
    opacity: 0.9;
}

.outline:focus {
    border: 1px solid var(--primary-color) !important;
}

.dropdown-menu {
    width: 190px;
    border: 0 !important;
    box-shadow: var(--box-shadow) !important;
    padding: 12px;
}

.lead-manager .dropdown-menu {
    padding: 12px !important;
}

.dropdown-menu a {
    display: block;
}

.intent-drop .dropdown-menu a:hover {
    color: var(--white) !important;
}

.assigned-drop .dropdown-menu {
    width: 190px;
    border: 0;
    box-shadow: var(--box-shadow);
    padding: 12px;
}

.assigned-drop .dropdown-menu a.dropdown-item {
    background: var(--white);
    line-height: 22px;
    letter-spacing: 0.1px;
    color: var(--black-dark-800);
    border-radius: 4px;
    font-size: calc(var(--value) * 7);
    padding: 4px 8px;
    font-weight: 400;
}

.assigned-drop .dropdown-menu a.dropdown-item:hover,
.assigned-drop .dropdown-menu a.dropdown-item.selected {
    background: var(--black-dark-200);
}

.assigned-drop .dropdown-menu a+a {
    margin-top: 8px;
}

.progress-dropdown .dropdown-menu a {
    background: var(--white);
    line-height: 22px;
    letter-spacing: 0.1px;
    color: var(--black-dark-800);
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 8px;
}

.progress-dropdown .dropdown-menu a+a {
    margin-top: 8px;
}

.progress-dropdown button {
    background: no-repeat;
    border: 0;
}

.progress-dropdown .dropdown-menu {
    width: 190px;
    border: 0;
    box-shadow: var(--box-shadow);
    padding: 12px;
}

.progress-dropdown .dropdown-menu a:hover {
    background: var(--black-dark-200);
}


/* header end css */


/* side bar css */

.notification {
    margin-right: 24px;
}

header button {
    border: 0;
    background: transparent;
}

.main-div {
    display: flex;
    flex: 0 0 100%;
    /* height: calc(var(--app-height) - var(--header-height)); */
    height: 100vh;
    background-color: var(--white);
}

.sidebar {
    transition: 0.5s all;
    width: 64px;
    flex: 0 0 64px;
    background: var(--white);
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    position: relative;
    box-shadow: inset -1px 0px 0px var(--black-dark-300);
    z-index: 9;
    height: 100%;
    /* overflow: hidden; */
}

.sidebar ul li a {
    color: var(--black-dark-700);
    font-size: 14px;
    line-height: 22px;
    display: block;
    transition: 0.5s all;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.sidebar ul li a:hover {
    color: var(--primary-color);
}

.sidebar ul li a:hover svg path {
    fill: var(--primary-color);
}

.sidebar ul li a svg {
    margin-right: 14px;
    transition: 0.5s all;
    flex: 0 0 20px;
}

.sidebar ul li+li {
    margin-top: 8px;
}

.sidebar ul li a {
    padding: 8px 10px;
}

.sidebar ul li.active,
.sidebar ul li:hover {
    border-radius: 8px;
    color: var(--primary-color) !important;
    background: rgb(0 98 255 / 10%);
}

.sidebar ul li a span {
    opacity: 0;
}

.sidebar ul li.active svg path,
.sidebar ul li:hover svg path {
    stroke: var(--primary-color);
    fill: none;
}

.logout {
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 100%;
    padding: 12px;
}

.logout a {
    color: var(--black-dark-700);
    font-size: 14px;
    line-height: 22px;
    display: block;
    width: 100%;
    padding: 8px 10px;
    letter-spacing: 0.1px;
    display: flex;
    overflow: hidden;
    align-items: center;
}

.logout a span {
    opacity: 9;
    flex: 0 0 100px;
    width: 100px;
}

.logout a svg {
    margin-right: 14px;
    flex: 0 0 20px;
    transition: 0.5s all;
}

.logout a:hover {
    border-radius: 8px;
    color: var(--primary-color);
    background: rgb(0 98 255 / 10%);
}

.logout a:hover svg path {
    fill: var(--primary-color);
}

.main-section {
    padding: 20px 5px 20px 20px;
    flex: 0 0 calc(100% - 64px);
    width: calc(100% - 64px);
    position: relative;
    overflow: hidden;
}


/* sidebar end css */


/* alerts */

.alert {
    border: 0;
    background: var(--white);
    box-shadow: 0px 0px 6px rgb(226 226 234 / 60%);
    border-radius: 4px;
    border-left: 6px solid black !important;
    padding: 16px 30px 16px 0px;
}

.alert h3 {
    color: var(--black);
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 16px;
}

.alert p {
    margin-bottom: 0;
    color: var(--black-dark-700);
    font-size: 14px;
    line-height: 22px;
}

.alert .info {
    margin-left: 16px;
    margin-right: 20px;
}

.alert .icon {
    margin-left: 16px;
}

.alert.alert-success {
    border-color: #3DD598 !important;
}

.alert.alert-info {
    border-color: var(--primary-color) !important;
}

.alert.alert-warning {
    border-color: #FFC542 !important;
}

.alert.alert-danger {
    border-color: #FC5A5A !important;
}

.alert {
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin: 0;
    overflow: hidden;
}


/* end alerts style */


/* input desing */

.form-control {
    background: var(--white);
    border: 1px solid var(--black-dark-400);
    padding: 4px 20px 4px 40px;
    border-radius: 8px !important;
    line-height: 22px;
    color: var(--black-dark-800);
    font-weight: 400;
    font-size: 14px;
    transition: 0.5s all;
}

.form-group+.form-group {
    margin-top: 16px;
}

.form-group label {
    font-size: 14px;
    line-height: 22px;
    color: var(--black-dark-700);
    margin-bottom: 8px;
    letter-spacing: 0.5px;
}

.mandatory input {
    border: 1px solid var(--primary-color) !important
}

.mandatory label {
    color: var(--primary-color);
}

.invalid .form-control {
    border: 1px solid var(--red) !important;
}

.invalid label {
    color: var(--red) !important;
}

.invalid .invalid-icon {
    opacity: 9 !important;
}

.form-group .invalid-icon {
    opacity: 0;
}

.invalid-icon {
    position: absolute;
    right: 10px;
    top: 44px;
}

.invalid .input-feedback {
    color: var(--red);
    font-size: 12px;
}

span.loder {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid var(--black-dark-700);
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

.tag-dropdown button {
    letter-spacing: 0.8px;
    font-size: 12px;
    font-weight: 500;
    background: #F0F6FF;
    border-radius: 2px;
    margin-left: 4px;
    color: var(--black-dark-800);
    opacity: 9 !important;
}

.tag-dropdown .dropdown-menu {
    min-width: 220px;
    width: auto;
}


.tag-dropdown .dropdown-menu a.dropdown-item {
    padding-left: 10px;
    padding-right: 0;
}

.tag-dropdown .dropdown-menu a.dropdown-item span.drop-tag {
    padding: 0 calc(var(--value) * 4);
    background: var(--black-dark-200);
    margin-left: 9px;
}

/* .dropdown-item {
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
} */

span.title {
    background: #F1F1F5;
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 13px;
    text-transform: capitalize;
    position: absolute;
    left: 90%;
    bottom: -25px;
    min-width: 170px;
    text-align: center;
    transition: 0.5s all;
    opacity: 0;
}

.lead-table .table tbody a.name:hover .title {
    opacity: 9;
    color: var(--black);
}

.custom-loader {
    color: transparent;
    appearance: none;
    -webkit-appearance: none;
    background-color: #eee;
    pointer-events: none;
    animation: loading-skeleton 1s infinite alternate;
    width: 162px;
    height: 33px;
    display: inline-block;
}


@keyframes loading-skeleton {
    from {
        opacity: 0.4;
    }

    to {
        opacity: 1;
    }
}


.edit-input-text input[type="text"] {
    padding-right: 30px !important;
}

.sidebar-modal .modal-dialog {
    max-width: 400px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 0;
    border: 0;
    min-height: 100vh;
    margin-top: 0;
    margin-bottom: 0;
}

.sidebar-modal .modal-dialog .modal-content {
    border: 0;
    border-radius: 0;
    max-height: 100vh;
    min-height: 100vh;
}

.sidebar-modal .modal-header.w-100 {
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
}

.sidebar-modal .card {
    border-bottom: 1px solid var(--black-dark-400) !important;
    border: none;
    border-radius: 0;
    padding: 0 !important;
    background: none !important;
    box-shadow: unset;
}

.sidebar-modal .card-data label input[type='checkbox'] {
    width: 18px;
    height: 18px;
}

.sidebar-modal .card .card-header {
    padding: 12px 18px;
    border: 0;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 22px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}



.sidebar-modal .modal-body {
    padding: 0;
}

.sidebar-modal .card-header {
    border-bottom: 1px solid transparent;
}

.sidebar-modal .card-header[aria-expanded="true"] svg {
    transform: rotateX(180deg);

}

.sidebar-modal .card-header svg {
    transition: 0.5s all;
}

.sidebar-modal .card-header[aria-expanded="true"] {
    border-bottom: 1px solid var(--black-dark-400) !important;
}



.sidebar-modal .card-data label {
    margin-top: 16px;
    cursor: pointer;
}

.sidebar-modal .card-data label:last-child {
    margin-bottom: 16px;
}

.sidebar-modal .card-data label {
    margin-bottom: 0;
}


.sidebar ul li a span {
    position: absolute;
    opacity: 0;
    left: 66px;
    z-index: 9999;
    background: var(--black-dark-800);
    color: var(--white);
    padding: 7px 20px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    transition: 0.5s all;
}

.sidebar ul li a span:before {
    content: " ";
    width: 14px;
    height: 17px;
    background: var(--black-dark-800);
    position: absolute;
    left: -14px;
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
    top: 9px;
}

.pagination-custom li.page-item a:hover svg path:nth-child(1) {
    fill: var(--primary-color);
}

.pagination-custom li.page-item a:hover svg path:nth-child(2) {
    fill: var(--white);
}

span.view-pass:hover svg path {
    fill: var(--primary-color);
    cursor: pointer;
}

.new-leads {
    font-weight: 500;
}

.new-leads .assigned-drop>button {
    border-color: var(--black-dark-800);
    font-weight: 500;
}

.new-leads .assigned-drop button#dropdownMenuButton svg path {
    fill: var(--black-dark-800);
}

.project-address {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    margin-right: 20px;
}

.modal-conents {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.highlightOption {
    background-color: transparent !important;
    color: var(--black-dark-800) !important;
}

.highlightOption:hover {
    color: white !important;
}

.multiSelectContainer li {
    text-transform: capitalize !important;
}

.multiSelectContainer li:hover {
    background-color: var(--primary-color) !important;
}

.no-internet p {
    margin-bottom: 24px;
}

.no-internet {
    padding: 30px 0 48px 0;
    text-align: center;
}

/* 
.lead-assignment_table .css-1s2u09g-control {
    flex-wrap: nowrap !important;
    max-width: 360px !important;
    overflow: scroll !important;
    flex-direction: row !important;
}

.lead-assignment_table .css-g1d714-ValueContainer {
    flex-wrap: nowrap !important;
    max-width: 270px !important;
    overflow: scroll !important;
    flex: 0 0 900px !important;
    padding: 0 !important;
}

.lead-assignment_table .css-1s2u09g-control {
    max-width: 360px !important;
    overflow: scroll !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.lead-assignment_table .css-1pahdxg-control {
    max-width: 360px !important;
    overflow: scroll !important;
}

.lead-assignment_table .css-g1d714-ValueContainer>div {
    min-width: auto !important;
} */



.dropdown-menu.mini-timeline.show {
    width: 450px;
    height: 260px;
    overflow-y: scroll;
    left: auto !important;
    right: 12px !important;
    overflow-x: hidden;
    padding-top: 0 !important;
    position: fixed;
    right: 20px !important;
    z-index: 1;
    top: 35%;
}

.show-mini-timeline .fade.modal-backdrop.show {
    display: none;
}



.fade.miniTimelineMain.modal-background.modal.show {
    width: unset;
    right: 0;
    left: auto;
}

.css-t35glz-MenuList>div {
    cursor: pointer;
    border-radius: 4px !important;
}

.css-t35glz-MenuList>div+div {
    margin-top: 8px;
}


.css-t35glz-MenuList {
    padding: 12px !important;
    background: white !important;
    z-index: 9 !important;
    border: 0 !important;
    box-shadow: var(--box-shadow) !important;
}

.css-26l3qy-menu {
    background: white;
    z-index: 9 !important;
}

.css-26l3qy-menu {
    width: calc(100% - 3px) !important;
    left: 2px !important;
    box-shadow: var(--box-shadow) !important;
    border: 0 !important;
}

li.search {
    display: none;
}

.react-tel-input .country-list .search {
    display: none;
}

.react-tel-input .country-list {
    box-shadow: var(--box-shadow);
    border: 0;
    margin: 5px 0 0 0;
    border-radius: 4px !important;
    max-height: 150px;
    bottom: 50px;
    top: auto;
}


.css-yt9ioa-option {
    line-height: 22px !important;
    letter-spacing: 0.1px !important;
    color: var(--black-dark-800) !important;
    font-size: calc(var(--value) * 7) !important;
    padding: 4px 8px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    font-family: var(--popins) !important;
}

.css-1n7v3ny-option {
    background: var(--black-dark-200) !important;
    line-height: 22px !important;
    color: var(--black-dark-800) !important;
    font-family: var(--popins) !important;
    font-weight: 400 !important;
    padding: 4px 8px !important;
    font-size: 14px !important;
    letter-spacing: 0.1px !important;
    /* text-transform: capitalize !important; */
}

.css-9gakcf-option {
    background: var(--black-dark-200) !important;
    text-transform: capitalize !important;
    line-height: 22px !important;
    color: var(--black-dark-800) !important;
    padding: 4px 8px !important;
    font-size: 14px !important;
    letter-spacing: 0.1px !important;
    font-family: var(--roboto) !important;
}

.css-1pahdxg-control {
    box-shadow: unset !important;
}

/* .css-yt9ioa-option {
    text-transform: capitalize !important;
} */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.tabsactive a.active {
    border-bottom: 1px solid var(--primary-color) !important;

}

.autowidht .tooltip-inner {
    max-width: unset;
}

.tabs-bottom-active-border .nav a:before {
    content: " ";
    position: absolute;
    left: 0;
    bottom: -9px;
    width: 100%;
    height: 1px;
    background: var(--primary-color);
    opacity: 0;
}

.tabs-bottom-active-border .nav a {
    position: relative;
}

.tabs-bottom-active-border .nav a.active:before {
    opacity: 9;
}

.tabs-bottom-active-border .nav a.active {
    color: var(--primary-color) !important;
}

.miniTimelineMain .modal-content::-webkit-scrollbar {
    background: white;
    border-radius: 0;
}

.miniTimelineMain .modal-dialog {
    margin-left: auto;
    margin-right: 0;
}

.show-mini-timeline .modal-body {
    padding-top: 0;
}

.reengaged .modal-dialog {
    margin-left: auto;
    margin-right: 0;
}

.miniTimelineMain .modal-content {
    max-width: 420px;
    min-width: 420px;
    margin-left: auto;
    right: 10px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 0;
    border-radius: 0;
    box-shadow: var(--box-shadow);
}

.reengaged .modal-content {
    max-width: 300px;
    min-width: 300px;
    margin-left: auto;
    right: 10px;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 0;
    border-radius: 0;
    box-shadow: var(--box-shadow);
}

.show-mini-timeline .modal-body {
    padding-top: 0;
}

.merge-del-btn>div {
    white-space: nowrap;
}

.del-modal {
    background-color: rgb(0 0 0 / 50%);
}

.secure-para svg {
    flex: 0 0 16px;
    max-width: 16px;
    margin-right: 8px;
}

.secure-para {
    font-size: 12px !important;
    line-height: 18px !important;
}

.logout button svg:nth-child(2) {

    display: none;
}

.logout button:hover svg:nth-child(1) {

    display: none;
}

.logout button:hover svg:nth-child(2) {
    display: block;

}

/* .secure-btn.tooltip[x-placement="right"] {
    inset: 12px auto auto 20px !important;
} */


.secure-btn.tooltip[x-placement="right"] .tooltip-arrow {
    display: none;
}

.sidebar-tooltip .tooltip-arrow {
    display: none;
}

.sidebar-tooltip.tooltip[x-placement="right"]:after {
    top: 25% !important;
}



.sidebar-tooltip.tooltip[x-placement="right"] {
    inset: 0px auto auto 25px !important;
}

.sidebar-tooltip p {
    font-size: 14px !important;
    line-height: 22px !important;
}

.sidebar-tooltip .tooltip-inner {
    padding: 8px 20px !important;
}

.logout button {
    margin-left: auto;
    margin-right: auto;
    display: table;
}

.css-4ljt47-MenuList {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.css-26l3qy-menu {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

@media screen and (min-width: 2000px) {
    /* .pagination-custom {
        flex-basis: calc(100% - 40px) !important;
        width: calc(1400px - 40px) !important;
        left: auto !important;
        right: auto !important;
    } */

    div#root {
        max-width: 1890px;
        /* max-width: 1440px; */
        margin: auto;
    }
}

button:disabled,
button[disabled] {
    border: 0;
    background-color: var(--black-dark-500);
    color: var(--white);
    cursor: no-drop;
}

.sidebar ul li button {
    padding: 0;
}

.sidebar ul li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

}

.sidebar:hover {
    overflow: visible;
}

.sidebar ul li:hover {
    overflow: visible;
}

button:disabled,
button[disabled]:hover {
    background-color: var(--black-dark-500);
}

.pagination-custom {
    box-shadow: 0px -4px 12px rgba(23, 23, 37, 0.04);
    border-top: 1px solid var(--black-dark-300);
}

.intent-drop .dropdown-menu.dead-lead-dropdown {
    width: 290px;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}

.intent-drop .dropdown-menu.dead-lead-dropdown svg {
    width: 24px;
    flex: 0 0 24px;
}

.intent-drop .dropdown-menu.dead-lead-dropdown p {
    white-space: break-spaces;
}

.intent-drop .dropdown-menu.dead-lead-dropdown::-webkit-scrollbar {
    background: var(--white);
}


.intent-drop .dropdown-menu.dead-lead-dropdown a.close {
    position: absolute;
    right: 9px;
    top: 6px;
    padding: 0;
}

.dead-lead-dropdown-content {
    max-height: 160px;
    overflow: scroll;
}

::-webkit-scrollbar {
    width: 13px;
    height: 12px;
    background: transparent;
    border-radius: 10px;
    background-clip: padding-box;
    z-index: 999999;
    position: relative;


}

.lead-table::-webkit-scrollbar-button:vertical:start:decrement {
    height: 48px !important;
    display: block !important;
    color: #d9d9d9;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: none !important;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    border: 2px solid var(--white);
    background: #d9d9d9;
    box-sizing: border-box;
    -webkit-border-radius: 10px;
    z-index: 99999;
    position: relative;

}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--black-dark-500);
    border: none;


}

.lead-pagination {
    width: 100%;
    height: 400px;
    background: var(--white);
}

tr.nohover:hover td,
tr.nohover td {
    background: white !important;
    border-bottom: none !important;

}

tr.nohover:after {
    display: none !important;
}


.pr-btn.rest-btn {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0px;
}

.logout a span {
    position: absolute;
    opacity: 0;
    left: 76px;
    z-index: 9999;
    background: var(--black-dark-800);
    color: var(--white);
    padding: 7px 20px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    transition: 0.5s all;
}


.logout a:hover span {
    opacity: 9;
    display: inline-block;
}

/* .logout:hover {
    overflow: visible;
}

.logout {
    overflow: hidden;
} */

.logout a:hover span:before {
    content: " ";
    width: 14px;
    height: 17px;
    background: var(--black-dark-800);
    position: absolute;
    left: -14px;
    -webkit-clip-path: polygon(0 50%, 100% 100%, 100% 0);
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
    top: 9px;
}

.login-log {
    max-width: 200px;
    display: table;
    margin: auto;
}

.side-banner .logo a {
    max-width: 200px;
    display: table;
}

a.header-logo {
    max-width: 200px;
    display: block;
}

.card.forget-msg {
    padding-top: 96px;
}

.my-login-page .card.forget-msg .login-log img {
    margin: 0;
}



.my-login-page .card .forget-pass-msg {
    margin-top: 42px;
}

.remove a:hover {
    color: var(--red) !important;
}

.all-fliters .clear-btn:hover {
    color: var(--primary-color);
}

textarea.form-control,
textarea {
    padding: 10px 12px !important;
}

input[type=date] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 45px;
    line-height: 42px;
}

input[type=time] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 45px;
    line-height: 42px;
}



input[type='checkbox']:hover,
button {
    cursor: pointer;
}

.settings-title .searchinput {
    height: 34px;
    width: 100%;
}

.thankyou {
    height: 100vh;
}

.login-page {
    display: flex;
    flex: 1 1 auto;
    background: var(--white);
}

.name-pagination .user-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
}

.fade.leadduplication.modal.show {
    background: #00000054;
}

.modal-radius .modal-content {
    border-radius: 20px;
}

.three-dots {
    display: inline-block;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
}

.virticledots {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.react-tel-input .form-control {
    padding-left: 48px !important;
    width: 100% !important;
}

/* Subscription modal */
.subscribe-modal p {
    padding: 0 24px;
    margin-top: 8px;
}

.subscribe-modal p a {
    color: var(--primary-color);
}

.re-engagedbtn {
    margin-left: 8px;
    font-family: var(--popins);
    font-size: 14px;
    background: var(--Blue-100);
    color: var(--primary-color);
    border-radius: 4px;
    padding: 7px 16px;
    font-weight: 500;
    white-space: nowrap;
}

.re-engagedbtn span {
    margin-left: 4px;
}

.secure-for-modal {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    font-family: var(--popins);
    color: #696974;
    position: absolute;
    bottom: 30px;
    left: 25px;
}

.secure-for-modal svg {
    margin-right: 8px;
}

.overflowY-hidden {
    overflow-y: hidden !important;
}

.w-100px {
    width: 100px;
}

.Toastify__close-button {
    margin-top: 0px !important;
    top: 0 !important
}


.search_input {
    position: relative;
}

.search_icon {
    position: absolute;
    top: 11px;
    left: 10px;
    z-index: 1;

}

.form-control.search_input {
    padding-left: 38px !important;
    padding-right: 33px !important;
}

.search_close {
    position: absolute;
    right: 14px;
    top: 17px;
}

.pr-outline-btn {

    border-radius: 8px;
    border: 1px solid rgba(0, 98, 255, 0.60);
    background: #E5EFFF;
    padding: 0;
    font-size: 14px;
    font-family: var(--inter);
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-color);
    padding: 9px 20px;

}

.react-datepicker-popper {
    z-index: 9 !important;
}

.strokered:hover svg path {
    stroke: var(--red)
}

.strokeprimary:hover svg path {
    stroke: var(--primary-color);
}

.fillblack800:hover path {
    fill: var(--black-dark-800) !important
}

.strokeck800:hover path {
    stroke: var(--black-dark-800) !important
}

/* .crisp-client[aria-live=polite] .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb {
    width: 40px !important;
    height: 40px !important;
}

.crisp-client[aria-live=polite] .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb[data-has-unread='false'] span.cc-11f2 span.cc-15e7 {
    top: 7px !important;
    width: 18px !important;
    left: 12px !important;
}

.crisp-client[aria-live=polite] .cc-tlyw[data-last-operator-face=false][data-full-view=false] .cc-nsge[data-maximized=true] .cc-imbb[data-has-unread='false'] span.cc-11f2 span.cc-15e7 {
    top: 11px !important;
    left: 11px !important;
    width: 18px !important;
} */

.crisp-client .cc-1brb6[data-position-reverse=true] .cc-1yy0g .cc-1m2mf,
.crisp-client .cc-1brb6[data-position-reverse=true] .cc-1yy0g .cc-1no03 {
    bottom: 8px !important;
    left: 0px !important;
}

.edit-note-pencil svg path {
    fill: var(--primary-color)
}

.ck-editor__editable {
    height: 200px;
    overflow: auto;
}

.titlebackbtn:hover svg path {
    stroke: var(--black);
}

.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.svg-active:hover svg path {
    fill: none;
    stroke: var(--primary-color);
}

.sidebar-svg.active svg path,
.sidebar-svg:hover svg path {
    stroke: none !important;
    fill: var(--primary-color) !important;
}


.customer-app.active .activeicon,
.customer-app:hover .activeicon {
    display: block !important;
}

.customer-app .activeicon {
    display: none;
}

.customer-app.active .customericon,
.customer-app:hover .customericon {
    display: none;
}

.customer-app .customericon {
    display: block;
}


.inventory-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 1px;
    width: 29px;
    /* Ensure the line is long enough to cover the diagonal */
    height: 1px;
    /* Adjust the thickness of the line */
    background-color: #0062FF;
    /* Color of the diagonal line */
    transform: rotate(45deg);
    /* Rotate the line to be diagonal */
    transform-origin: left top;
}

.grid-slider .slick-prev {
    left: -15px !important;
    z-index: 9;
}

.grid-slider .slick-next {
    right: -2px !important;
    z-index: 9;
}

.grid-slider .slick-next::before,
.grid-slider .slick-prev::before {
    content: none !important;
}

.inventory-grid-box-shadow {
    box-shadow: 0px 4px 15px 0px rgba(52, 64, 84, 0.12);
}

.border-line {
    width: 86px;
    height: 1px;
    background: linear-gradient(90deg, #FFF 0%, #D0D5DD 50%, #FFF 100%) !important;
}

.inventory-floor-shadow {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.facing-strip-shadow {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)
}

.yarl__container {
    background-color: #171725cc !important;
}

.yarl__slide_title_container {
    text-align: center !important;
}

.yarl__icon {
    height: 40px !important;
    width: 40px !important;
}

.yarl__toolbar {
    top: -7px !important;
}

.yarl__slide_title {
    --yarl__toolbar_width: 0 !important;
    font-weight: 500 !important;
}

.yarl__slide_captions_container {
    background: #171725 !important;
}

.info_box {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(252, 90, 90, 0.10) 100%), #FFF !important;
}

.sv-info .dropdown-menu {
    border: 1px solid #FC5A5A !important;
}

.broker-lightbox .yarl__navigation_prev {
    display: none !important;
}

.broker-lightbox .yarl__navigation_next {
    display: none !important;
}

.ck-editor__editable_inline {
    padding: 0 30px !important;
}